import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo title="Guides" />
        <Hero title="Guides" />
        <ArticlePreview posts={posts} />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
        allContentfulBlogPost(filter: { node_locale: {eq: "da-DK"} }, sort: { fields: [publishDate], order: DESC }, limit: 75) {
          nodes {
            node_locale
            id
            title
            slug
            publishDate(formatString: "D/M-YYYY")
            description {
              raw
            }
            image {
                gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
    }
  }
`
